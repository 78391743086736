<!-- EditBackfillPool.vue -->
<template>
  <div>
    <v-form ref="form" v-model="editFormIsValid">
      <v-card>
        <v-card-title class="pb-0">
          <p class="headline">Edit Pool Percentage</p>
        </v-card-title>

        <v-card-text class="pb-0">
          <v-container class="pt-0 pb-0">
            <v-row align="center" justify="center">
              <!-- Percentage -->
              <v-col
                cols="12"
                :sm="8"
                :md="12"
                class="pb-0"
              >
                <v-slider
                  :readonly="!!editedItem.locked || readOnlyPools"
                  class="pt-6"
                  :thumb-size="24"
                  thumb-label="always"
                  v-model="editedItem.percentage"
                  :max="
                    editedItem.enabled && editedItem.locked
                      ? 100 - lockedPercent + Number(initialPercent)
                      : 100 - lockedPercent
                  "
                >
                  <template v-slot:append>
                    <v-text-field
                      v-model="editedItem.percentage"
                      class="mt-0 pt-0"
                      hide-details
                      single-line
                      type="number"
                      style="width: 60px"
                    ></v-text-field>
                  </template>
                </v-slider>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog('cancel')">Cancel</v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updatePools()"
            :disabled="!editFormIsValid || submitting || readOnlyPools"
            >Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>

export default {
  props: ["closeDialog", "updatePools"],

  data: () => ({
    editFormIsValid: true,
    submitting: false,
  }),
  computed: {
    editedIndex() {
      return this.$store.state.backfillPools.editedIndex;
    },
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    editPercent() {
      return this.$store.state.backfillPools.editPercent;
    },
    initialPercent() {
      return this.$store.state.backfillPools.initialPercent;
    },
    isEditing() {
      return this.$store.state.backfillPools.isEditing;
    },
    lockedPercent() {
      return this.$store.state.backfillPools.lockedPercent;
    },
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-list-item__content {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 30px;
}
::v-deep .v-input__slot {
  align-items: center;
  justify-content: center;
  font-size: 12px;
  height: 15px;
  max-height: 15px !important;
  margin-bottom: 0 !important;
}
</style>
