<template>
  <v-row justify="center" class="mb-1">
    <!---------------------------------------------------------------
        Confirm Dialog for Call To Action AutoComplete Replacement 
    ----------------------------------------------------------------->
    <v-dialog
      v-model="confirmDialog"
      max-width="600"
      persistent
    >
      <v-card>
        <v-card-title class="headline justify-center">Found Matching Call To Action</v-card-title>
        <v-card-text class="justify-center text-center">
          Found Matching <u>Call To Action</u> (<b>{{foundCTA}}</b>) For <u>Content Type:</u> <b>{{feed.defaultCT}}</b>
          <br><br>Would You Like To Replace The Current Set Value?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            outlined
            @click="confirmDialog = false"
          >No</v-btn>

          <v-btn
            color="green darken-1"
            outlined
            @click="updateCTA"
          >Yes</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---------------------------------------------------------------
        Feed URL Dialog Form
    ----------------------------------------------------------------->
    <v-toolbar height="50" flat color="white">
      <v-spacer></v-spacer>
      <v-dialog persistent v-model="$store.state.backfillPools.feedUrlDialog" scrollable max-width="1200px">
        <template v-slot:activator="{ on }">
          <v-btn color="primary" dark class="ml-2 addFeedBtn" v-on="on" :disabled="readOnlyPools || submitting"
            >New Feed <v-icon right>mdi-plus</v-icon>
          </v-btn>
          <app-backfill-pools-master-geo-target v-if="editedItem.typeData.length > 0" :submitting="submitting"></app-backfill-pools-master-geo-target>
        </template>
        <v-form ref="form" v-model="isValid">
          <v-card>
            <v-card-title>
              {{editedFeedIndex == -1 ? "Add Feed Url" : "Edit Feed Url"}}
              <v-spacer></v-spacer>
              <app-backfill-pools-feed-mapping :feedFields="feedFields" :assetFields="assetFields" :submitting="submitting"
                v-if="this.feed.mapping && Object.keys(this.feed.mapping).length > 0"></app-backfill-pools-feed-mapping>
            </v-card-title>

            <v-card-text :style="validated || editedFeedIndex != -1 ? 'height: 650px' : 'height: 300px'" class="pl-0 pr-0 pb-0">
              <v-row  justify="center">
                <v-col cols="6" class="pb-0 pt-10 pr-0">
                  <v-text-field
                    class="addUrl"
                    :class="{'read-only': validated || editedFeedIndex != -1}"
                    :readonly="validated || editedFeedIndex != -1"
                    height="40"
                    placeholder="Add Feed Url"
                    v-model="feed.feedUrl"
                    solo
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="1" class="pl-0 pt-10 pb-0">
                  <!-- Validate Button -->
                  <v-btn v-if="!validated && editedFeedIndex == -1"
                    class="searchBtn primary"
                    @click="addFeedUrl(feed.feedUrl)"
                    :disabled="readOnlyPools || validating"
                    height=40
                    :loading="validating"
                    text
                  >
                    <v-icon>mdi-plus</v-icon>Validate
                  </v-btn>
                  <!-- Validated Button -->
                  <v-btn v-if="validated || editedFeedIndex != -1"
                    height=40
                    class="success"
                    readonly
                    text
                  >
                    <v-icon class="pr-2">mdi-check</v-icon>Validated
                  </v-btn>
                </v-col>
              </v-row>

              <v-container>
                <v-row justify="center" align="center" class="pt-10">
                  <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      :readonly="readOnlyPools"
                      outlined
                      placeholder="Default Organization"
                      v-model="feed.defaultOrg"
                      v-on:keydown="disabledDefaults"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span> Default Organization
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
                    <v-combobox
                      @change="getCTA"
                      dense
                      :items="docTypes"
                      outlined
                      placeholder="Default Content Type"
                      :readonly="readOnlyPools"
                      v-model="feed.defaultCT"
                      v-on:keydown="disabledDefaults"
                    >
                      <template #label>
                        <span class="red--text"><strong>* </strong></span> Default Content Type
                      </template>
                    </v-combobox>
                  </v-col>

                  <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
                    <v-text-field
                      dense
                      :disabled="readOnlyPools || loadingCTA"
                      :readonly="readOnlyPools"
                      :loading="loadingCTA"
                      outlined
                      placeholder="Call To Action"
                      v-model="feed.defaultCTA"
                      v-on:keydown="disabledDefaults"
                    >
                     <template #label>
                        <span class="red--text"><strong>* </strong></span> Call To Action
                      </template>
                    </v-text-field>
                  </v-col>

                  <v-col cols="4" class="pt-0 pb-0">
                    <v-checkbox class="mt-2 ml-8" type="number" v-show="validated || editedFeedIndex != -1" :readonly="readOnlyPools" v-model.number="feed.override" label="Override (Apply To All Feed Items)"></v-checkbox>
                  </v-col>

                  <v-col cols="12" align-self="center" class="pt-0 pb-0">
                    <v-col cols="4" offset="4" align-self="center">
                      <app-backfill-pools-geo-target v-show="validated || editedFeedIndex != -1"></app-backfill-pools-geo-target>
                    </v-col>
                  </v-col>

                  <v-col cols="8">
                    <v-textarea
                      auto-grow
                      clearable
                      class="pb-0 preserve-whitespace"
                      counter
                      label="Targeting Notes"
                      name="input-7-4"
                      outlined
                      :rules="descriptionRules"
                      v-model="feed.targeting_notes"
                      v-show="validated || editedFeedIndex != -1">
                    </v-textarea>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeFeedDialog()" :disabled="loadingCTA">Cancel</v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveFeed"
                :disabled="!(feed.defaultOrg && feed.defaultCT && feed.defaultCTA) || loadingCTA || !isValid"
                >{{editedFeedIndex == -1 ? "Add" : "Update" }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
    </v-toolbar>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import BackfillPoolsFeedMapping from "@/components/medusa/BackfillPoolsFeedMapping";
import BackfillPoolsGeoTarget from "@/components/medusa/BackfillPoolsGeoTarget";
import BackfillPoolsMasterGeoTarget from "@/components/medusa/BackfillPoolsMasterGeoTarget";

export default {
  props: ["origFeedTypeData", "submitting"],

  components: {
    appBackfillPoolsFeedMapping: BackfillPoolsFeedMapping,
    appBackfillPoolsGeoTarget: BackfillPoolsGeoTarget,
    appBackfillPoolsMasterGeoTarget: BackfillPoolsMasterGeoTarget
  },

  data: () => ({
    assetFields: ['contentType', 'description', 'guid', 'link', 'pubDate', 'title', 'topic'],
    confirmDialog: false,
    descriptionRules: [v => (v || '').length <= 255 || 'Max 255 characters'],
    docTypes: [],
    feedFields: [],
    foundCTA: '',
    isValid: true,
    loadingCTA: false,
    validated: false,
    validating: false
  }),
  created() {
    //Used For Feed Content Type Auto-select
    this.axios
      .get(`/medusa/pools/get_doc_type_desc`)
      .then((res) => {
        for (let row of res.data) {
          this.docTypes.push(row["DocTypeDesc"]);
        }
      });
  },
  computed: mapState({
    defaultFeed(){
      return this.$store.state.backfillPools.defaultFeed;
    },
    editedFeedIndex(){
      return this.$store.state.backfillPools.editedFeedIndex;
    },
    editedIndex() {
      return this.$store.state.backfillPools.editedIndex;
    },
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    feed() {
      if(this.editedFeedIndex > -1)
        return this.editedItem.typeData[this.editedFeedIndex];
      else
        return this.defaultFeed;
    },
    feedUrlDialog(){
      return this.$store.state.backfillPools.feedUrlDialog;
    },
    origPools(){
      return this.$store.state.backfillPools.origPools;
    }, 
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
    unsavedGeo(){
      return this.$store.state.backfillPools.unsavedGeo;
    },
    unsavedMapping(){
      return this.$store.state.backfillPools.unsavedMapping;
    }
  }),

  methods: {
    ...mapMutations({
      resetDefaultFeed: "backfillPools/resetDefaultFeed",
      setEditedFeedIndex: "backfillPools/setEditedFeedIndex",
      setFeedUrlDialog: "backfillPools/setFeedUrlDialog",
      setMappingDialog: "backfillPools/setMappingDialog",
      setUnsavedFeeds: "backfillPools/setUnsavedFeeds",
      setUnsavedGeo: "backfillPools/setUnsavedGeo",
      setUnsavedMapping: "backfillPools/setUnsavedMapping"
    }),

    addFeedUrl() {
      this.validating = true;
      if (this.validateUrl(this.feed.feedUrl.trim())) {
        let url = this.feed.feedUrl;
        let msg = "";
        let color = "";
        let position = "";
        //Verify that Url hasnt already been added but not yet saved in DB
        if (this.editedItem.typeData.length > 0) {
          for (var data of this.editedItem.typeData) {
            if (data.feedUrl.replace(/\/$/, "") == url.replace(/\/$/, "")) {
              this.$store.commit("sendAlert", {
                msg: "Feed URL Already Exists For This Pool",
                color: "error",
                position: "top"
              });
              this.validating = false;
              return;
            }
          }
        }
        //Verify that feed url contains items
        this.axios
          .get(`/medusa/pools/verify_feed_url?feed_url=${encodeURIComponent(url)}&pool_id=${this.editedItem.pool_id}`)
          .then((res) => {
            let finalUrlMsg = "";
            if(res.data.final_url){
              finalUrlMsg = "<br><b>Redirect Url:</b> " + res.data.final_url;
            }
            //If Duplicate Url
            if (res.data.error) {
              this.$store.commit("sendAlert", {
                msg: res.data.error + finalUrlMsg,
                color: "error",
                position: "top"
              });
            } else if (res.status == 200) {
              if (res.data != null) {
                if (res.data.item && res.data.item.length > 0) {
                  this.feed.feedUrl = this.feed.feedUrl.trim();
                  this.feed.assetCount = res.data.item.length;
                  this.validated = true;
                  this.setUnsavedFeeds(true);

                  this.editedItem.typeDataAltered = true;
                  this.editedItem.items += res.data.item.length;

                  msg = `URL Verifed Succssfully And Contains ${res.data.item.length} items`;
                  color = "success";

                  let item = res.data.item[0];

                  if(res.data.final_url){
                    this.feed.feedUrl = res.data.final_url.trim();
                  }
                  this.feed.feedFields = Object.keys(item);
                  this.feed.assetFields = JSON.parse(JSON.stringify(this.assetFields));
                  //Check if all the necessary feed asset fields exist for url being added
                  for(let field of JSON.parse(JSON.stringify(this.feed.assetFields.reverse()))){
                    let index = this.feed.feedFields.indexOf(field);
                    if(index > -1){
                      //If a mapping match exists, remove it from the assetFields & feedFields arrays
                      this.feed.mapping[field] = field;
                      let assetIndex = this.feed.assetFields.indexOf(field);
                      this.feed.assetFields.splice(assetIndex,1);
                      this.feed.feedFields.splice(index, 1);
                    } else {
                      this.feed.mapping[field] = null;
                    }
                  }
                  if(this.feed.assetFields.length == 0 && this.feed.feedFields == 0)
                    this.feed.mapping = null;

                  if(res.data.site && res.data.site != ""){
                    this.feed.defaultOrg = res.data.site;
                  }
                  if(this.feed.assetFields && this.feed.assetFields.length > 0 && this.feed.feedFields && this.feed.feedFields.length > 0) this.setMappingDialog(true);
                } else {
                  msg = "Feed Doesn't Contain Any Items And Cannot Be Added";
                  color = "error";
                }
              } else {
                msg = "Feed Doesn't Contain Any Items And Cannot Be Added";
                color = "error";
              }
              this.$store.commit("sendAlert", {
                msg: msg,
                color: color,
                position: position
              });
              this.validating = false;
            } else {
              this.$store.commit("sendAlert", {
                msg: `URL Was Not Successfully Verifed, Status Code: ${res.status}`,
                color: "error"
              });
            }
            this.validating = false;
          }); //If Url Isn't formatted correctly
      } else {
        this.$store.commit("sendAlert", {
          msg: "Error In Url Format, Ex:https://www.zdnet.com/feed/partner/partner-name/rss.xml",
          color: "error",
          position: "top"
        });
        this.validating = false;
      }
    },
    /**
     * On Close, if the feed wasn't saved/updated, reset it back to its original value(s)
     *
     * @param {string} action
     */
    closeFeedDialog(action = null){
      //Reset Edited Feed Url Values if dialog is closed and not saved
      if(action != "saved"){
        let msg = null;
        if(this.unsavedGeo && this.unsavedMapping){
          msg = "Leave Without Updating Geo Tracking & Mapping Data?";
        } else if(this.unsavedGeo){
          msg = "Leave Without Updating Geo Tracking Data?";
        } else if(this.unsavedMapping){
          msg = "Leave Without Updating Mapping Data?";
        }

        if(msg){
          var r = confirm(msg);
          if (r != true) {
            return;
          } else {
            this.setUnsavedGeo(false);
            this.setUnsavedMapping(false);
          }
        }
        if(this.editedFeedIndex != -1 && this.feed.feedId != null){
          this.editedItem.typeData[this.editedFeedIndex] = this.origFeedTypeData[this.editedFeedIndex];
          //The above line causes the two variables to turn into objects instead of sets
          this.editedItem.typeData[this.editedFeedIndex].countriesAdded = new Set();
          this.editedItem.typeData[this.editedFeedIndex].countriesRemoved = new Set();
        }
      }
      this.setEditedFeedIndex(-1);
      this.setFeedUrlDialog(false);

      //Reset Default Item
      this.validated = false;
      this.resetDefaultFeed();
    },
    /**
     * An Alert That is triggered when a user attempts to fillout form values prior to verifying a url
     */
    disabledDefaults(e){
      if(this.editedFeedIndex == -1 && !this.validated){
        e.preventDefault();
        alert("Please Add A Verifed Feed Url Before Adding Defaults");
        return;
      }
    },
    /**
     * Call To Action AutoComplete Feature, triggered after Content Type is filled out
     *
     * @param {string} url
     */
    getCTA(){
      let origFeedIndex = this.origPools.findIndex((pool) => pool.pool_id == this.editedItem.pool_id);
      let origFeedUrl = (this.editedFeedIndex == -1 ? '' : this.origPools[origFeedIndex].typeData[this.editedFeedIndex]);
        
      if(this.feed.defaultCT.length > 3) {
        //If the value hasn't changed, don't trigger autocomplete search
        if(origFeedUrl.defaultCT == this.feed.defaultCT)
          return;
        
        this.loadingCTA = true;
         //Verify that feed url contains items
        this.axios
          .get(`/medusa/pools/get_cta?content_type=${this.feed.defaultCT}`)
          .then((res) => {
            if(res.data.cta.length > 0){
              if(this.feed.defaultCTA){
                //If a CTA Value already exists and != to the found autocomplete value, show confirmDialog
                if(this.feed.defaultCTA.length > 0 && this.feed.defaultCTA != res.data.cta){
                  this.foundCTA = res.data.cta;
                  this.confirmDialog = true;
                }
              } else {
                this.feed.defaultCTA = res.data.cta;
              }
            }
            this.loadingCTA = false;
        });
      }
    },
    /**
     * Save/Update Feed Url data to the pools typedata
     */
    saveFeed(){
      if(this.editedFeedIndex == -1){
        this.editedItem.typeData.unshift(this.feed);
      } else if (this.editedFeedIndex > -1) {
        Object.assign(this.editedItem.typeData[this.editedFeedIndex], this.feed);
        this.editedItem.typeDataAltered = true;
      }
      this.setUnsavedFeeds(true);
      this.setUnsavedGeo(false);
      this.closeFeedDialog("saved");
    },
    setMapping(){
      if(this.editedFeedIndex != -1){
        this.feed.assetFields = [];
        this.feed.feedFields = [];
        if(Object.keys(this.feed.mapping).length > 0){
          delete this.feed.mapping.feed_id;
          for(let [index, field] of Object.entries(this.feed.mapping)){
            if(index != field){
              this.feed.assetFields.push(index);
              this.feed.feedFields.push(field);
            }
          }
        }
      }
    },
    /**
     * Update current CTA value to the CTA autocomplete value
     */
    updateCTA(){
      this.feed.defaultCTA = this.foundCTA;
      this.foundCTA = '';
      this.confirmDialog = false;
    },
    /**
     * Validate Feed Urls
     *
     * @param {string} url
     */
    validateUrl(url) {
      var re = /^(http|https):\/\/(([a-zA-Z0-9$\-_.+!*'(),;:&=]|%[0-9a-fA-F]{2})+@)?(((25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])(\.(25[0-5]|2[0-4][0-9]|[0-1][0-9][0-9]|[1-9][0-9]|[0-9])){3})|localhost|([a-zA-Z0-9\-\u00C0-\u017F]+\.)+([a-zA-Z]{2,}))(:[0-9]+)?(\/(([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*(\/([a-zA-Z0-9$\-_.+!*'(),;:@&=]|%[0-9a-fA-F]{2})*)*)?(\?([a-zA-Z0-9$\-_.+!*'(),;:@&=?]|%[0-9a-fA-F]{2})*)?(\\#([a-zA-Z0-9$\-_.+!*'(),;:@&=\\/?]|%[0-9a-fA-F]{2})*)?)?$/;
      if (re.test(url)) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
      feedUrlDialog(feedUrlDialog){
        if(feedUrlDialog && this.feed.mapping){
          this.setMapping();
        }
      }
    }
};
</script>
<style scoped lang="scss">
  .preserve-whitespace{
    white-space: pre-line;
  }
  .row{
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  ::v-deep .addUrl .v-input__slot{
    min-height: 20px !important;
  }
  .read-only{
    opacity: .6;
    font-style: italic;
  }
  ::v-deep .addFeedBtn.theme--dark.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
    background-color: #1976d2 !important;
    border-color: #1976d2 !important;
    opacity: .5;
  }
</style>
