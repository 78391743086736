<template>
  <v-toolbar dark color="teal">
    <v-toolbar-title class="title mr-6 mt-4">
      <v-select
        v-model="searchOn"
        dense
        :disabled="readOnlyPools"
        :items="searchValues"
        class="searchFilter"
        @change="docs = []"
      ></v-select>
    </v-toolbar-title>
    <v-autocomplete
      v-model="editedItem.typeData"
      :items="items"
      :search-input.sync="search"
      flat
      hide-no-data
      hide-details
      hide-selected
      item-text="docDescription"
      item-value="DocId"
      label="Begin typing a Doc Id, Doc Title, or Organization"
      :loading="isLoading"
      :menu-props="{ auto: true, closeOnContentClick: true }"
      multiple
      @beforeinput="keymonitor"
      :beforeinput="keymonitor"
      no-data-text="No Results Found"
      :disabled="readOnlyPools"
      ref="auto-input"
      return-object
      solo-inverted
      @input="search = null"
      @change="editedItem.typeDataAltered = true"
    >
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item.DocId"></v-list-item-content>
        </template>
        <template>
          <v-list-item-content :class="{ 'pt-2 pb-2': data.item.LineOrderNumber }">
            <v-list-item-title
              :class="{ strike: data.item.Active == false && searchOn == 'All' }"
              v-html="data.item.DocTitle"
            ></v-list-item-title>
            <v-list-item-subtitle
              v-html="data.item.DocId + ' - ' + data.item.OrgName"
            ></v-list-item-subtitle>
            <v-list-item-subtitle v-if="data.item.LineOrderNumber" class="red--text"
              >* Document Sponsored By Line Order:
              {{ data.item.LineOrderNumber }}</v-list-item-subtitle
            >
            <v-list-item-subtitle v-if="data.item.pool_id"
              ><b
                ><i
                  >Doc Exists In Pool: <u>{{ data.item.name }}</u></i
                ></b
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </template>
      <!-- empty selection slot so that chips arent added -->
      <template v-slot:selection=""> </template>
    </v-autocomplete>
  </v-toolbar>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    docs: [],
    docTitleLimit: 100,
    isLoading: false,
    request: undefined,
    search: null,
    searchOn: "Active",
    searchValues: ["Active", "All"],
    menuProps: {
      closeOnClick: true,
      closeOnContentClick: true
    }
  }),

  computed: {
    editedItem() {
      return this.$store.state.backfillPools.editedItem;
    },
    fields() {
      if (!this.editedItem.typeData) return [];
      return this.editedItem.typeData["DocId"];
    },

    items() {
      return this.docs.map((doc) => {
        let docDescription = doc.DocId + " " + doc.OrgName + " " + doc.DocTitle;

        let DocTitle =
          doc.DocTitle.length > this.docTitleLimit
            ? doc.DocTitle.slice(0, this.docTitleLimit) + "..."
            : doc.DocTitle;

        doc.disabled = doc.pool_id ? true : false;

        doc.DocPubDate = new Date(doc.DocPubDate.replace(/-/g, '/') + ' GMT+8').toLocaleDateString();
        doc.ModifiedDate = new Date(doc.ModifiedDate.replace(/-/g, '/') + ' GMT+8').toLocaleString();

        doc.Active = doc.DocStatusId == 1 ? true : false;
        return Object.assign({}, doc, {
          docDescription,
          DocTitle
        });
      });
    },
    readOnlyPools() {
      return this.$store.state.backfillPools.readOnlyPools;
    },
  },
  methods: {
    keymonitor: function(event) {
      //Prevent backspace in autocomplete input from deleting any or all selected options **Doesnt always work**
      if (event.target.value.length == 0 && event.key == "Backspace") {
        event.preventDefault();
        this.$refs["auto-input"].blur();
        return;
      }
    }
  },

  watch: {
    "editedItem.typeData": function(newV, oldV) {
      if(oldV){
        if (newV.length != oldV.length && newV.length > 0) {
          if (newV[newV.length - 1].Active) {
            //Item Count is for active items
            this.editedItem.items += 1;
          } else {
            this.editedItem.inactiveItems += 1;
          }
        }
      } else {
        if(newV[0].Active) this.editedItem.items += 1;
        else this.editedItem.inactiveItems += 1;
      }
      this.$refs["auto-input"].blur();
    },
    search(val) {
      if (val == null) return;
      if (val.length < 2) return;

      const axiosSource = axios.CancelToken.source();

      if (this.request) this.request.cancel();
      this.request = { cancel: axiosSource.cancel };

      this.isLoading = true;

      this.axios
        .get(`medusa/pools/doc_autocomplete?term=${val}&searchOn=${this.searchOn}`, {
          cancelToken: axiosSource.token
        })
        .then((res) => {
          this.request = undefined;
          this.docs = res.data;

          if (this.docs.length == 0) {
            this.$store.commit("sendAlert", {
              msg: "No Search Results",
              color: "error",
              position: "top"
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          if (axios.isCancel(err)) {
            // request cancelled
          }
          console.log(err);
        })
        .finally(() => (this.isLoading = false));
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .v-list-item.v-list-item--link.theme--light {
  min-height: 40px;
  &:nth-of-type(odd) {
    background-color: rgba(150, 50, 50, 0.2);
  }

  .v-list-item__content {
    padding-top: 0;
    padding-bottom: 0;

    .v-list-item__title,
    .v-list-item__subtitle {
      font-size: 12px;
    }
  }
}
::v-deep .v-list-item--disabled {
  background-color: gray !important;
  border-bottom: 1px silver solid;
  .v-list-item__content {
    padding: 6px 0;

    .v-list-item__title,
    .v-list-item__subtitle {
      font-size: 12px;
      color: white !important;
    }
  }
}
.searchFilter {
  width: 155px;
  max-width: 155px;
}
.strike {
  text-decoration: line-through;
}
</style>